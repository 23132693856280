export default [
  {
    path: '/deviceMonitor',
    name: 'deviceMonitor',
    component: () => import(/* webpackChunkName: "deviceMonitor" */ './index'),
    meta: {
      type: 'deviceMonitor'
    },
    redirect: '/deviceMonitor/alertManagement',
    children: [
      {
        path: 'alertManagement',
        name: 'alertManagement',
        component: () => import(/* webpackChunkName: "deviceMonitor" */ './alertManagement')
      },
      {
        path: 'deviceSelfTest',
        name: 'deviceSelfTest',
        component: () => import(/* webpackChunkName: "deviceMonitor" */ './deviceSelfTest')
      },
      {
        path: 'electronicScalesReminder',
        name: 'electronicScalesReminder',
        component: () =>
          import(/* webpackChunkName: "deviceMonitor" */ './electronicScalesReminder')
      },
      {
        path: 'settingManagement',
        name: 'settingManagement',
        component: () => import(/* webpackChunkName: "deviceMonitor" */ './settingManagement')
      }
    ]
  }
]
